@media (max-width: 1680px) and (min-width: 1280px) {
  .inner {
    width: 1170px;
  }
  main.aside {
    gap: var(--rowGap) 20px;
  }
  .product-bottom-wrapper {
    padding: 8px 12px;
  }
  .greeting-section + .second-section {
    margin-top: -40px;
  }
  .greeting-section {
    grid-template-columns: 769px minmax(0, 1fr);
  }
  .greeting-section .mainpage-greeting {
    gap: 12px;
    padding: 30px 20px 30px 30px;
  }
  .greeting-section .greeting-headline {
    font-size: 19px;
  }
  .greeting-section .greeting-body {
    font-size: 14px;
  }
  .second-section {
    grid-template-columns: minmax(0, 1fr) 600px;
  }
  .headline,
  .promo-header .headline {
    font-size: 20px;
  }
  .mainpage-products-slider {
    padding: 16px 24px;
    gap: 24px;
  }
  .mainpage-products-slider .promo-header {
    height: 30px;
  }
  .mainpage-products-slider .swiper-buttons-wrapper .products-slider-button {
    height: 30px;
    width: 30px;
  }
  .product-single-promoted {
    grid-template-columns: 180px minmax(0, 1fr);
  }
  .product-single-promoted .product-name,
  .product-single-promoted p {
    font-size: 14px;
    --fontSize: 14px;
  }
  .blog-banners-wrapper {
    gap: 24px;
  }
  .product {
    gap: 24px;
  }
  .list-view .product {
    gap: 24px;
    padding-inline: 16px 24px;
    grid-template-columns: 180px minmax(0, 1fr) auto;
  }
  .detail-main-wrapper {
    grid-template-columns: 500px minmax(0, 4fr) minmax(0, 5fr);
    gap: 0 24px;
  }
  #detail-left {
    margin-left: 0;
  }
  #detail-right {
    padding: 18px 24px;
  }
  .product-images {
    width: 500px;
  }
  .product-images .size-full {
    width: 500px;
    height: 333px;
  }
  .product-images-mini {
    height: 107px;
  }
  .product-images-mini .size-custom {
    width: 107px;
    height: 71px;
  }
  #detail-traits .trait-name {
    width: 24%;
  }
  .tree-top-hover {
    width: 290px;
  }
  #main-bar {
    gap: 20px;
  }
  .mainmenu {
    gap: 18px;
  }
  .mainmenu li a,
  .tree-top-hover {
    font-size: 13px;
  }
  #tree-top a,
  #tree-top li a {
    font-size: 14px;
  }
  .grid-banner .banner-text-wrapper {
    margin-inline: 16px;
    width: calc(100% - 32px);
    top: 60%;
  }
  .grid-banner .banner-text-wrapper b,
  .grid-banner .banner-text-wrapper p,
  .grid-banner .banner-text-wrapper i {
    font-size: 12px;
  }
  .mainpage-products-slider {
    gap: 12px;
  }
  .product-single-promoted {
    flex-direction: column;
  }
  .infobox.comparison-empty:not(:only-child) h3 {
    font-size: 20px;
  }
  .comments-list {
    grid-template-columns: minmax(0, 1fr);
  }
  .comments-list .comment-info {
    justify-content: flex-start;
    gap: 20px;
  }
  .comments-list .comment-info .purchase-confirmed {
    margin-left: auto;
  }
}
@media (max-width: 1680px) and (min-width: 1280px) and (max-width: 1280px) {
  #detail-images .swiper-button-prev,
  #detail-images > swiper-button-next {
    top: calc(500px * 0.5 - 20px);
  }
}
@media (max-width: 1279px) {
  .detail-omnibus {
    align-self: flex-start;
    margin-top: 0;
    font-size: 11px;
  }
  .footer-bottom-bar {
    padding: 30px 0 100px;
  }
  .detail-omnibus {
    text-align: left;
  }
  main.aside {
    grid-template-columns: minmax(0, 1fr);
    gap: var(--rowGap);
  }
  .inner {
    width: 100%;
    padding: 0 10px;
  }
  * {
    -webkit-tap-highlight-color: rgba(189, 195, 199, 0.75);
    -webkit-text-size-adjust: none;
  }
  body.privacy-box-visible {
    overflow: hidden;
  }
  body.privacy-box-visible #container {
    filter: blur(5px);
    pointer-events: none;
  }
  body:has(.left-side-cookies) .privacy-box-trigger {
    bottom: 15px;
    width: 35px;
    height: 35px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.right-side-cookies {
    right: 15px;
  }
  body:has(.left-side-cookies) .privacy-box-trigger.left-side-cookies {
    left: 15px;
  }
  .privacy-box {
    bottom: auto;
    right: auto;
    left: 15px !important;
    right: 15px !important;
    top: 50%;
    transform: translateY(-50%);
    max-width: 100vw;
    max-height: calc(100% - 40px);
  }
  input[type=text],
  input[type=password],
  input[type=email],
  select,
  textarea {
    font-size: 16px;
    padding: 0 14px;
  }
  textarea {
    padding: 10px 14px;
  }
  #facebook-box,
  .detail-loupe {
    display: none;
  }
  #content {
    width: 100%;
    clear: both;
  }
  .sidebar {
    width: auto;
    display: contents;
  }
  .sidebar .sidebar-tabs {
    display: none;
  }
  .inner.aside {
    display: flex;
    flex-direction: column;
  }
  #search-engine {
    display: none;
  }
  .newsletter-input input {
    text-align: center;
  }
  .newsletter-header {
    text-align: center;
    gap: 8px;
  }
  .newsletter-header b {
    font-size: 20px;
  }
  .newsletter-input {
    width: 100%;
    max-width: 700px;
  }
  .footer-content {
    flex-direction: column;
    padding: 20px 0;
  }
  .footer-item {
    width: 100% !important;
  }
  body:has(.gs-filtr-container) .filter-trigger {
    display: flex;
    margin-left: 0;
    padding: 0 36px 0 10px;
  }
  body:has(.gs-filtr-container) .filter-trigger:after {
    right: 10px;
  }
  .navigation-wrapper {
    gap: 10px 16px;
  }
  .inner-wrapper [data-dropdown-type="pageSize"] {
    margin-left: auto;
  }
  .dropdown-picker[data-dropdown-type="sort"] {
    display: none;
  }
  .listing-button {
    height: 40px;
    font-size: 13px;
    min-width: 200px;
  }
  .listing-button.filter-trigger {
    min-width: 0;
    gap: 5px;
    align-items: center;
    font-weight: bold;
    color: #241623;
    border-color: #241623;
    justify-content: center;
  }
  .listing-button.filter-trigger:before {
    content: 'i sortowanie';
    position: relative;
    top: initial;
    left: initial;
    padding: 0;
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    order: 1;
  }
  .listing-button.filter-trigger svg,
  .listing-button.filter-trigger:after {
    top: calc(50% - 1px);
    right: 16px;
  }
  #filter {
    display: none;
  }
  hr {
    margin: 20px 0;
  }
  .overlay-visible {
    overflow: hidden;
    height: 100dvh;
    width: 100dvw;
    position: fixed;
    top: 0;
  }
  main:not(.aside) #tree {
    display: block;
  }
  .tree-canvas {
    position: fixed;
    background: #000000 none repeat scroll 0% 0%;
    will-change: opacity;
    transition: opacity 300ms ease 0s;
    inset: 0px;
    z-index: 1001;
    pointer-events: none;
    opacity: 0;
  }
  #tree {
    position: fixed;
    top: 0;
    left: 0;
    transform: translate3d(-100%, 0, 0);
    overflow: hidden;
  }
  .user-custom-content p {
    margin-bottom: 10px;
  }
  .detail-main-wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
  }
  #detail-images {
    align-items: center;
    flex-direction: column;
    gap: 20px;
    height: auto;
    left: auto;
    margin: 0 auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  #detail-images .swiper-button-prev,
  #detail-images .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
  }
  #detail-images .swiper-button-prev {
    left: 10px;
  }
  #detail-images .swiper-button-next {
    right: 10px;
  }
  .product-images-mini {
    order: initial;
    height: auto;
    width: 100%;
  }
  .product-images-mini .swiper-wrapper {
    transform: none !important;
    width: auto;
    justify-content: center;
  }
  .product-images-mini .size-custom {
    width: 10px;
    height: 10px;
    background-color: #B8DBD9;
    border-radius: 50%;
    margin: 0 5px;
    padding: 0 !important;
  }
  .product-images-mini .size-custom.active {
    background-color: #08577C;
  }
  .product-images-mini img {
    display: none !important;
  }
  .headline {
    font-size: 20px !important;
    margin-bottom: 12px;
  }
  .product {
    gap: 5px;
  }
  .product .product-bottom-wrapper {
    padding: 5px;
  }
  .product-single-promoted .product-name,
  .comparison-product-name,
  .product a.product-name,
  .product p {
    font-size: 12px !important;
    --fontSize: 12px !important;
  }
  .top-bar {
    padding: 3px 10px 4px;
    font-size: inherit;
  }
  .top-bar a:before,
  .top-bar li:before {
    font-size: 18px;
  }
  .shop-header {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease-in-out;
  }
  #tree-top,
  .mainmenu,
  .sidebar-banners-wrapper {
    display: none;
  }
  .hamburger-icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 70%;
    height: 25px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
  }
  .hamburger-icon span {
    display: block;
    height: 2px;
    border-radius: 1px;
    background-color: #241623;
  }
  .hamburger-icon span:nth-child(2) {
    width: 80%;
  }
  .shop-header-container {
    padding-block: 7px;
  }
  .logo {
    height: 60px;
  }
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    .header-tools-wrapper > * {
      margin-left: 10px;
    }
    .hamburger-icon {
      display: grid;
      grid-auto-rows: max-content;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, 6px);
      gap: 0;
      justify-items: center;
      align-items: center;
    }
    .tree-canvas {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  .compare-box,
  #cart-box,
  #wishlist-box,
  .log-button,
  .search-engine-trigger,
  .tree-trigger {
    padding-top: 29px;
  }
  .compare-box em,
  #cart-box em,
  #wishlist-box em,
  .log-button em,
  .search-engine-trigger em,
  .tree-trigger em {
    font-size: 9px;
  }
  [data-type="mainpage"] main.inner {
    margin-top: 16px;
  }
  [data-type="listing"] main.inner {
    margin-top: 0;
  }
  .greeting-section + .second-section {
    margin-top: -16px;
  }
  .greeting-section {
    display: flex;
    gap: 16px;
  }
  .greeting-section .main-banner {
    width: calc(66% - 8px);
  }
  .greeting-section .mainpage-greeting {
    flex: 1;
  }
  .mainpage-greeting {
    gap: 8px;
    padding: 24px;
  }
  .mainpage-greeting .greeting-headline {
    font-size: 15px;
  }
  .mainpage-greeting .greeting-body {
    font-size: 12px;
  }
  .second-section {
    display: flex;
    gap: 16px;
  }
  .second-banner {
    width: calc(49% - 8px);
  }
  .mainpage-products-slider .promo-header {
    height: 30px;
    gap: 20px;
  }
  .mainpage-products-slider .promo-header a {
    white-space: nowrap;
  }
  .mainpage-products-slider .promo-header .swiper-buttons-wrapper .products-slider-button {
    height: 30px;
    width: 30px;
  }
  .second-banner .banner-text-wrapper {
    bottom: 5%;
    padding: 14px 40px;
  }
  .banner-text-wrapper b {
    font-size: 14px;
  }
  .banner-text-wrapper span {
    font-size: 12PX;
  }
  .mainpage-promo {
    gap: 12px;
  }
  .grid-banner .banner-text-wrapper {
    left: 0;
    right: 0;
    width: fit-content;
    margin: 0 auto !important;
    max-width: 90%;
    top: initial;
    bottom: 0;
  }
  .grid-banner .banner-text-wrapper b {
    font-size: 12px;
    font-weight: normal;
  }
  .grid-banner .banner-text-wrapper i,
  .grid-banner .banner-text-wrapper p {
    display: none !important;
  }
  .grid-banners-wrapper {
    gap: 16px;
  }
  .blog-banners-section {
    gap: 12px;
  }
  .blog-banners-wrapper {
    gap: 20px 16px;
  }
  .blog-banner {
    gap: 12px;
  }
  .blog-banner p {
    font-size: 12px;
    --fontSize: 12px;
  }
  .blog-banner p:nth-child(3) {
    margin-top: -6px;
  }
  .blog-banner i {
    height: 27px;
    padding: 8px 12px;
  }
  .producers-banner {
    padding: var(--rowGap);
  }
  .footer-content > * {
    flex-basis: initial !important;
    max-width: initial !important;
  }
  .footer-content > * > strong {
    margin-bottom: 18px;
  }
  .location-hours > div {
    padding-left: 32px;
  }
  .location-hours > div svg,
  .location-hours > div:before {
    width: 20px;
    font-size: 20px;
  }
  .footer-contact > strong {
    margin-bottom: 16px;
  }
  .footer-contact .contact-list a:before {
    font-size: 18px;
  }
  .footer-map {
    margin-top: 0;
  }
  .footer-map:before {
    display: none;
  }
  .footer-bottom-bar {
    padding-block: 20px 70px;
    flex-direction: column;
    gap: 16px;
  }
  #breadcrumb {
    padding-block: 10px;
  }
  .listing-wrapper {
    gap: var(--rowGap);
  }
  .listing-button.filter-trigger {
    margin-left: auto;
    font-size: 0;
    color: #08577C;
    border: 1px solid #08577C;
  }
  .listing-button.filter-trigger:before {
    content: 'Filtry i sortowanie';
    font-size: 13px;
  }
  .listing-button.filter-trigger:after {
    font-size: 20px;
    color: #08577C;
  }
  .listing-button.page-size {
    min-width: initial !important;
  }
  .inner-wrapper [data-dropdown-type="pageSize"] {
    margin-left: initial;
  }
  .pagination a:after {
    font-size: 20px;
  }
  .detail-stars-wrapper i {
    position: relative;
    top: -1px;
  }
  .detail-headline {
    text-align: center;
  }
  #detail-left {
    margin-left: 0;
  }
  #detail-left > * {
    padding-bottom: 20px;
  }
  #detail-right {
    position: relative;
    top: initial;
    box-shadow: none;
    padding: 20px 10px;
  }
  #main-info {
    margin-top: 0;
  }
  .detail-promo-badge {
    position: absolute;
    top: 10px;
    right: 10px;
  }
  .detail-producer-logo {
    max-height: 50px;
  }
  .detail-pricing {
    justify-content: flex-start;
  }
  .detail-pricing:before {
    display: none;
  }
  .detail-pricing:after {
    content: 'Cena brutto:';
    width: 100%;
    display: flex;
    justify-content: inherit;
    font-size: 12px;
  }
  .detail-pricing del {
    order: 2;
  }
  [data-role="product-nettoprice"] {
    font-size: 16px;
    justify-content: flex-start;
  }
  .detail-omnibus {
    text-align: start;
    margin-top: -14px;
  }
  #detail-tabs nav {
    gap: 16px;
    margin-bottom: 16px;
  }
  #detail-tabs nav button {
    font-size: 16px;
    min-height: 42px;
    padding-bottom: 8px;
  }
  .detail-comments-header {
    gap: 4px 10px;
  }
  .detail-comments-header > b {
    font-size: 20px;
    margin-right: 0;
  }
  .comments-list {
    gap: 24px;
    grid-template-columns: minmax(0, 1fr);
  }
  .comment-info {
    padding-inline: 0;
    justify-content: flex-start;
    gap: 20px;
  }
  .comment-info .purchase-confirmed {
    white-space: nowrap;
    margin-left: auto;
  }
  .comment-info .purchase-confirmed svg {
    height: 20px;
    width: 20px;
    margin-right: 7px;
  }
  .comment-info .purchase-confirmed:after {
    margin-inline: 16px;
    height: 24px;
  }
  .comment {
    gap: 10px;
  }
  .comment-response {
    margin-left: 16px;
  }
  .comments-stars-wrapper {
    white-space: nowrap;
  }
  .comments-stars-wrapper b {
    font-size: 16px;
  }
  .comments-stars-wrapper i {
    font-size: 14px;
  }
  .comparison-drawer {
    gap: 8px;
    padding: 10px;
  }
  .comparison-drawer > b {
    font-size: 12px;
  }
  .comparison-drawer .confirm-button {
    gap: 6px;
    font-size: 11px;
    padding: 6px 12px;
  }
  .comparison-drawer .confirm-button:before {
    font-size: 16px;
  }
  .infobox.comparison-empty:not(:only-child) h3 {
    font-size: 20px;
  }
}
@media (max-width: 1279px) and (min-width: 960px) {
  .mainpage-products-slider {
    flex: 1;
    padding: 10px;
    gap: 12px;
  }
  .product-single-promoted {
    height: 100%;
    grid-template-columns: 180px minmax(0, 1fr);
  }
  .product-single-promoted-info p {
    margin-top: 6px;
    font-size: 12px;
    --fontSize: 12px;
    -webkit-line-clamp: 2;
    max-height: calc(2 * (var(--fontSize) * var(--lineHeight)));
  }
  .product-single-promoted-info .product-price {
    margin-top: 6px;
    gap: 16px;
  }
}
@media (max-width: 1279px) and (max-width: 960px) {
  .greeting-section {
    flex-direction: column;
  }
  .greeting-section .main-banner {
    width: 100%;
  }
  .greeting-section .main-banner .banner-text-wrapper {
    margin: 0;
    bottom: 5%;
    left: 5%;
    padding: 14px 40px 14px 32px;
  }
  .greeting-section .mainpage-greeting {
    text-align: center;
    padding: 28px 10px;
    aspect-ratio: initial;
    height: initial;
    max-height: initial;
  }
  .greeting-section .mainpage-greeting .greeting-headline {
    font-size: 20px;
  }
  .greeting-section .mainpage-greeting .greeting-body {
    font-size: 13px;
    padding: 0;
  }
  .mainpage-products-slider {
    aspect-ratio: initial;
    height: initial;
    padding: 10px;
    gap: 10px;
  }
  .product-single-promoted {
    gap: 16px;
  }
  .product-single-promoted-info .product-name {
    max-height: initial;
    display: block !important;
  }
  .product-single-promoted-info p {
    margin-top: 6px;
    font-size: 12px;
    --fontSize: 12px;
    -webkit-line-clamp: 2;
    max-height: calc(2 * (var(--fontSize) * var(--lineHeight)));
  }
  .product-single-promoted-info .product-price {
    gap: 16px 24px;
    flex-wrap: wrap;
  }
  .second-section {
    flex-direction: column;
  }
  .second-banner {
    width: 100%;
  }
}
@media (max-width: 1100px) {
  #detail-traits td {
    padding: 8px 15px;
  }
  .header-tools-wrapper {
    gap: 24px;
  }
  .top-bar {
    gap: 16px;
  }
  .top-bar .topbar {
    gap: inherit;
  }
  .grid-banners-wrapper {
    gap: 8px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .grid-banners-wrapper b {
    font-size: 11px !important;
  }
  .top-bar {
    gap: 12px;
    font-size: 11px;
  }
  .top-bar .topbar a {
    font-size: 11px;
  }
  .header-contact {
    letter-spacing: inherit;
  }
  .header-contact li {
    gap: 6px;
  }
  .header-contact li:before {
    font-size: 16px;
  }
  .blog-banners-wrapper {
    gap: 20px 8px;
  }
  #detail-traits {
    gap: 0;
  }
  #detail-traits td {
    padding: 8px 15px;
  }
  #detail-traits .trait {
    flex-direction: column;
    gap: 6px;
    padding: 8px 0;
  }
  #detail-traits .trait b {
    width: 100%;
    justify-content: flex-start;
  }
  #detail-traits .trait .trait-desc {
    width: 100%;
  }
  .comments-list {
    grid-template-columns: minmax(0, 1fr);
    gap: 24px;
  }
  .comment-info {
    display: grid;
    grid-auto-rows: max-content;
    grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
    gap: 8px;
  }
  .comment-info .purchase-confirmed {
    margin-left: 0;
  }
  .comment-info .purchase-confirmed:after {
    display: none;
  }
  .comment-info .stars-rating {
    justify-self: end;
  }
  .comment-info .added-date {
    justify-self: end;
    text-align: end;
  }
}
@media (max-width: 1100px) and (max-width: 675px) {
  .icon-banner-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) and (max-width: 750px) {
  .product .brutto-price {
    justify-content: center;
  }
  .product .brutto-price b,
  .product .netto-price {
    text-align: center;
  }
  .product .brutto-price b:before,
  .product .netto-price:before {
    text-align: center;
  }
  .product .product-add {
    width: 100%;
    grid-column: 1 / 3;
    justify-content: center;
    margin-top: 4px;
  }
}
@media (max-width: 1100px) and (min-width: 455px) {
  .products-list.list-view {
    gap: 16px;
  }
  .list-view .product {
    padding-inline: 16px 8px;
    grid-template-columns: 180px minmax(0, 1fr) auto;
    gap: 16px;
  }
  .list-view .product .middle-info {
    padding-block: 16px;
  }
  .list-view .product .product-bottom-wrapper {
    padding-block: 16px;
    padding-inline: 16px 8px;
  }
}
@media (max-width: 1100px) and (min-width: 455px) and (max-width: 800px) {
  .list-view .product {
    grid-template-columns: 120px minmax(0, 1fr) auto;
  }
}
@media (max-width: 1100px) and (max-width: 700px) {
  .shop-header-container {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding-block: 4px 8px;
  }
  .shop-header-container .logo {
    height: 56px;
  }
  .shop-header-container .header-tools-wrapper {
    margin-right: 0;
    justify-content: space-evenly;
    width: 100%;
    gap: 16px;
  }
}
@media (max-width: 1100px) and (max-width: 900px) {
  .top-bar .hours {
    display: none;
  }
}
@media (max-width: 1100px) and (max-width: 700px) {
  .grid-banners-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) and (max-width: 800px) {
  .blog-banners-wrapper {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (max-width: 1100px) and (max-width: 800px) {
  .product-single-promoted {
    grid-template-columns: 180px minmax(0, 1fr);
  }
  .greeting-section .mainpage-greeting {
    padding: 20px 10px;
  }
}
@media (max-width: 1100px) and (max-width: 750px) {
  .infobox.comparison-empty:not(:only-child) .confirm-button {
    display: none;
  }
  .comparison-header {
    gap: var(--prodGap);
    flex-wrap: wrap;
  }
  .comparison-header .comparison-empty {
    width: 100%;
    flex-basis: auto;
  }
  .comparison-product {
    gap: 12px;
  }
  .comparison-product .comparison-info-bottom {
    padding: 8px;
  }
  .comparison-table {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 24px;
    padding-bottom: 0;
  }
  .comparison-table .row {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: none !important;
  }
  .comparison-table .row > div {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-end;
  }
  .comparison-table .row > div:empty {
    display: none !important;
  }
  .comparison-table .row > div:first-child {
    justify-content: flex-start;
    margin: 0;
    width: 100% !important;
    flex-basis: auto;
    text-align: left;
    display: block;
    line-height: normal;
    background-color: #F4F4F9;
    border-radius: 4px;
    color: #241623;
    padding: 6px 10px;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.08em;
  }
  .comparison-table .row > div:first-child:after {
    content: ':';
  }
  .comparison-table .row > div:not(:first-child) {
    display: flex;
    flex-wrap: wrap;
    font-weight: bold;
    color: #241623;
  }
  .comparison-table .row > div:not(:first-child):before {
    content: attr(data-model-name) ':';
    font-weight: normal;
    margin-right: 8px;
    color: #241623;
  }
}
@media (max-width: 600px) {
  .detail-share-links-wrapper span {
    width: 100%;
  }
  .stars-rating.average-rating {
    top: -2px;
  }
  .gs-tabs nav {
    flex-direction: column;
  }
  .gs-tabs nav button {
    width: 100%;
  }
  .user-custom-content iframe {
    max-width: 100%;
  }
  .privacy-box {
    padding: 10px;
    gap: 10px;
  }
  .privacy-box__header {
    gap: 10px;
  }
  .privacy-box__header img {
    height: 20px;
  }
  .privacy-box__header strong {
    font-size: 14px;
  }
  .privacy-box__actions {
    flex-wrap: wrap;
  }
  .privacy-box__actions button {
    min-height: 35px;
  }
  .privacy-box__actions button.privacy-accept-all {
    width: 100%;
    order: -1;
    flex: 0 0 auto;
    min-height: 45px;
  }
  .pagination {
    gap: 4px;
  }
  .pagination a {
    min-width: 30px;
    height: 30px;
  }
  .pagination a:after {
    font-size: 16px;
  }
  #breadcrumb {
    display: flex;
    margin-left: -10px;
    width: calc(100% + 20px);
    justify-content: flex-start;
    position: relative;
  }
  #breadcrumb .breadcrumb-end {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 20px;
    pointer-events: none;
  }
  #breadcrumb .breadcrumb-end:nth-of-type(1) {
    left: -7px;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb .breadcrumb-end:nth-of-type(2) {
    right: -7px;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #ffffff, #ffffff);
  }
  #breadcrumb ul {
    flex-wrap: nowrap;
    overflow-y: auto;
    justify-content: flex-start;
    flex-direction: row-reverse;
    padding-bottom: 5px;
  }
  #breadcrumb li {
    white-space: nowrap;
  }
  #breadcrumb li:last-of-type {
    padding-right: 10px;
  }
  #breadcrumb li:first-of-type {
    padding-left: 10px;
  }
  #breadcrumb li:nth-of-type(1) {
    order: 10;
  }
  #breadcrumb li:nth-of-type(2) {
    order: 9;
  }
  #breadcrumb li:nth-of-type(3) {
    order: 8;
  }
  #breadcrumb li:nth-of-type(4) {
    order: 7;
  }
  #breadcrumb li:nth-of-type(5) {
    order: 6;
  }
  #detail-images .swiper-button-prev {
    left: 0;
  }
  #detail-images .swiper-button-next {
    right: 0;
  }
  #gsGalleryContainer > a {
    width: 100%;
  }
  .availability-modal-wrapper .wrapper {
    justify-content: center;
    gap: 10px;
  }
  .availability-modal-wrapper .wrapper > p {
    text-align: center;
  }
  .availability-modal-wrapper .wrapper > .availability-product {
    justify-content: center;
  }
  .availability-modal-wrapper .availability-product {
    gap: 10px;
    padding: 10px 0;
  }
  .availability-modal-wrapper .input-wrapper {
    flex-wrap: wrap;
  }
  .availability-modal-wrapper .input-wrapper > * {
    width: 100%;
  }
  .product-images {
    width: 100%;
  }
  .product-images .size-full {
    width: 100%;
    height: auto;
    aspect-ratio: 6/4;
  }
  .detail-amount {
    width: 100%;
    margin: 0 0 8px;
  }
  .pills-group:before,
  .remove-all-filters {
    font-size: 11px;
  }
  .single-pill {
    font-size: 11px;
    padding: 2px 5px;
  }
  .detail-amount button:before {
    font-size: 22px !important;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] {
    flex-grow: 1;
  }
  .dropdown-picker[data-dropdown-type="pageSize"] .dropdown-button {
    width: 100%;
  }
  .headline,
  .greeting-headline {
    font-size: 16px !important;
  }
  .promo-header a {
    padding: 5px 8px !important;
    font-size: 9px !important;
  }
  .producers-banner {
    padding: 0 55px;
  }
  .producers-banner .swiper-slide {
    padding: 25px 0 !important;
  }
  .greeting-section .main-banner .banner-text-wrapper {
    padding: 10px 28px 10px 22px;
  }
  .second-banner .banner-text-wrapper {
    padding: 10px 28px;
  }
  .grid-banner .banner-text-wrapper b {
    padding: 5px;
  }
  .blog-banners-wrapper {
    grid-template-columns: repeat(2, mimnax(0, 1fr));
  }
  .header-contact {
    font-size: 11px;
    letter-spacing: inherit;
  }
  .header-contact li {
    gap: 6px;
  }
  .header-contact li:before {
    font-size: 16px;
  }
  .top-bar:has(.header-contact) .topbar {
    display: none;
  }
  .top-bar {
    width: 100%;
    justify-content: space-evenly;
  }
  .header-contact {
    width: 100%;
    justify-content: space-between;
  }
  .comparison-drawer {
    bottom: 0;
    right: 0;
    width: 100dvw !important;
  }
  .detail-stars-wrapper i {
    position: relative;
    top: -2px;
  }
  .mainpage-products-slider .promo-header {
    gap: 10px;
    height: 24px;
  }
  .mainpage-products-slider .promo-header .swiper-buttons-wrapper .products-slider-button {
    height: 24px;
    width: 24px;
  }
  .mainpage-products-slider .promo-header .swiper-buttons-wrapper .products-slider-button:after {
    font-size: 16px;
  }
  #detail-tabs nav {
    gap: 10px;
  }
  .detail-comments-header {
    justify-content: flex-start;
  }
  .detail-comments-header .headline {
    width: 100%;
  }
  .detail-comments-header > b {
    margin-left: 0;
  }
  .detail-comments-header .stars-rating,
  .detail-comments-header i {
    position: relative;
    top: -1px;
  }
}
@media (max-width: 600px) and (max-width: 350px) {
  .icon-banner-wrapper {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
@media (max-width: 600px) and (max-width: 454px) {
  .navigation-wrapper {
    flex-direction: column;
  }
  .view-type-wrapper {
    display: none;
  }
  .dropdown-picker {
    width: 100%;
    z-index: 11;
  }
  .listing-button {
    min-width: 0 !important;
    width: 100% !important;
  }
  .filter-trigger {
    margin-top: 0;
    order: 1;
  }
}
@media (max-width: 600px) and (min-width: 455px) {
  .list-view .product {
    grid-template-columns: 100px minmax(0, 1fr) auto;
  }
}
@media (max-width: 600px) and (max-width: 480px) {
  .footer-map {
    width: 100%;
  }
}
@media (max-width: 600px) and (max-width: 360px) {
  .grid-banners-wrapper {
    grid-template-columns: minmax(0, 1fr);
  }
}
@media (max-width: 600px) and (max-width: 435px) {
  .header-tools-wrapper .compare-box {
    display: none !important;
  }
}
@media (max-width: 600px) and (max-width: 560px) {
  .mainpage-products-slider {
    gap: 8px;
  }
  .product-single-promoted {
    gap: 8px;
    grid-template-columns: 140px minmax(0, 1fr);
  }
  .product-single-promoted-info .product-name {
    font-size: 12px;
  }
  .product-single-promoted-info p {
    margin-top: 8px;
    font-size: 12px;
    --fontSize: 12px;
  }
  .product-single-promoted-info .product-price {
    margin-top: 16px;
    gap: 8px 16px;
  }
}
@media (max-width: 600px) and (max-width: 440px) {
  .product-single-promoted {
    gap: 8px;
    grid-template-columns: 100px minmax(0, 1fr);
  }
}
@media (max-width: 600px) and (max-width: 480px) {
  .newsletter-header b {
    font-size: 16px;
  }
}
@media (max-width: 600px) and (max-width: 435px) {
  .main-banner .banner-text-wrapper b,
  .second-banner .banner-text-wrapper b {
    font-size: 12px;
  }
  .main-banner .banner-text-wrapper span,
  .second-banner .banner-text-wrapper span {
    display: none !important;
  }
}
