// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1680px) and (min-width: 1280px) {
    .inner {
        width: 1170px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    main.aside {
        gap: var(--rowGap) 20px;
    }

    .product-bottom-wrapper {
        padding: 8px 12px;
    }

    .greeting-section + .second-section {
        margin-top: -40px;
    }

    .greeting-section {
        grid-template-columns: 769px minmax(0, 1fr);

        .mainpage-greeting {
            gap: 12px;
            padding: 30px 20px 30px 30px;
        }

        .greeting-headline {
            font-size: 19px;
        }

        .greeting-body {
            font-size: 14px;
        }
    }

    .second-section {
        grid-template-columns: minmax(0, 1fr) 600px;
    }

    .headline,
    .promo-header .headline {
        font-size: 20px;
    }


    .mainpage-products-slider {
        padding: 16px 24px;
        gap: 24px;
    }

    .mainpage-products-slider .promo-header {
        height: 30px;
    }

    .mainpage-products-slider .swiper-buttons-wrapper .products-slider-button {
        height: 30px;
        width: 30px;
    }

    .product-single-promoted {
        grid-template-columns: 180px minmax(0, 1fr);

        .product-name, p {
            font-size: 14px;
            --fontSize: 14px;
        }
    }

    .blog-banners-wrapper {
        gap: 24px;
    }

    .product {
        gap: 24px;
    }

    .list-view .product {
        gap: 24px;
        padding-inline: 16px 24px;
        grid-template-columns: 180px minmax(0, 1fr) auto;
    }

    .detail-main-wrapper {
        grid-template-columns: 500px minmax(0, 4fr) minmax(0, 5fr);
        gap: 0 24px;
    }

    #detail-left {
        margin-left: 0;
    }

    #detail-right {
        padding: 18px 24px;
    }

    .product-images {
        width: 500px;

        .size-full {
            width: 500px;
            height: 333px;
        }
    }

    @media (max-width: 1280px) {
        #detail-images .swiper-button-prev,
        #detail-images > swiper-button-next {
            top: calc(500px * 0.5 - 20px);
        }
    }

    .product-images-mini {
        height: 107px;

        .size-custom {
            width: 107px;
            height: 71px;
        }
    }

    #detail-traits {

        .trait-name {
            width: 24%;
        }
    }

    .tree-top-hover {
        width: 290px;
    }

    #main-bar {
        gap: 20px;
    }

    .mainmenu {
        gap: 18px;
    }

    .mainmenu li a,
    .tree-top-hover {
        font-size: 13px;
    }

    #tree-top a, #tree-top li a {
        font-size: 14px;
    }

    .grid-banner .banner-text-wrapper {
        margin-inline: 16px;
        width: calc(100% - 32px);
        top: 60%;
        
        b, p, i {
            font-size: 12px;
        }
    }

    .mainpage-products-slider {
        gap: 12px;
    }

    .product-single-promoted {
        flex-direction: column;
    }

    .infobox.comparison-empty {

        &:not(:only-child) {

            h3 {
                font-size: 20px;
            }

        }
    }

    .comments-list {
        grid-template-columns: minmax(0, 1fr);

        .comment-info {
            justify-content: flex-start;
            gap: 20px;

            .purchase-confirmed {
                margin-left: auto;
            }
        }
    }
}