// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1279px) {

    .detail-omnibus {
        align-self: flex-start;
        margin-top: 0;
        font-size: 11px;
    }

    .footer-bottom-bar {
        padding: 30px 0 100px;
    }

    .detail-omnibus {
        text-align: left;
    }


    main.aside {
        grid-template-columns: minmax(0, 1fr);
        gap: var(--rowGap);
    }

    .inner {
        width: 100%;
        padding: 0 10px;
    }

    * {
        -webkit-tap-highlight-color: rgba(189, 195, 199, .75);
        -webkit-text-size-adjust: none;
    }

    body.privacy-box-visible {
        overflow: hidden;

        #container {
            filter: blur(5px);
            pointer-events: none;
        }
    }

    body:has(.left-side-cookies) {

        .privacy-box-trigger {
            bottom: 15px;
            width: 35px;
            height: 35px;

            &.right-side-cookies {
                right: 15px;
            }

            &.left-side-cookies {
                left: 15px;
            }
        }
    }

    .privacy-box {
        bottom: auto;
        right: auto;
        left: 15px !important;
        right: 15px !important;
        top: 50%;
        transform: translateY(-50%);
        max-width: 100vw;
        max-height: ~"calc(100% - 40px)";
    }

    input[type=text],
    input[type=password],
    input[type=email],
    select,
    textarea {
        font-size: 16px;
        padding: 0 14px;
    }

    textarea {
        padding: 10px 14px;
    }

    #facebook-box,
    .detail-loupe {
        display: none;
    }

    #content {
        width: 100%;
        clear: both;
    }

    .sidebar {
        width: auto;
        display: contents;

        .sidebar-tabs {
            display: none;
        }
    }

    .inner.aside {
        display: flex;
        flex-direction: column;
    }

    // SEARCH ENGINE
    #search-engine {
        display: none;
    }

    .newsletter-input input {
        text-align: center;
    }

    .newsletter-header {
        text-align: center;
        gap: 8px;

        b {
            font-size: 20px;
        }
    }

    .newsletter-input {
        width: 100%;
        max-width: 700px;
    }

    .footer-content {
        flex-direction: column;
        padding: 20px 0;
    }

    .footer-item {
        width: 100% !important;
    }

    body:has(.gs-filtr-container) .filter-trigger {
        display: flex;
        margin-left: 0;
        padding: 0 36px 0 10px;

        &:after {
            right: 10px;
        }
    }

    // LISTING NAVIGATION
    .navigation-wrapper {
        gap: 10px 16px;
    }

    .inner-wrapper [data-dropdown-type="pageSize"] {
        margin-left: auto;
    }

    .dropdown-picker[data-dropdown-type="sort"] {
        display: none;
    }

    .listing-button {
        height: 40px;
        font-size: 13px;
        min-width: 200px;

        &.filter-trigger {
            min-width: 0;
            gap: 5px;
            align-items: center;
            font-weight: bold;
            color: @header-color;
            border-color: @header-color;
            justify-content: center;

            &:before {
                content: 'i sortowanie';
                position: relative;
                top: initial;
                left: initial;
                padding: 0;
                color: inherit;
                font-size: inherit;
                font-weight: inherit;
                order: 1;
            }

            svg,
            &:after {
                top: calc(50% - 1px);
                right: 16px;
            }
        }
    }

    // FILTER
    #filter {
        display: none;
    }

    // OTHER
    hr {
        margin: 20px 0;
    }

    .overlay-visible {
        overflow: hidden;
        height: 100dvh;
        width: 100dvw;
        position: fixed;
        top: 0;
    }

    main:not(.aside) #tree {
        display: block;
    }

    .tree-canvas {
        position: fixed;
        background: rgb(0, 0, 0) none repeat scroll 0% 0%;
        will-change: opacity;
        transition: opacity 300ms ease 0s;
        inset: 0px;
        z-index: 1001;
        pointer-events: none;
        opacity: 0;
    }

    #tree {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        overflow: hidden;
    }

    .user-custom-content p {
        margin-bottom: 10px;
    }

    // DETAIL ORIENTATION CHANGE
    .detail-main-wrapper {
        align-items: stretch;
        display: flex;
        flex-direction: column;
        gap: 20px;
        position: relative;
    }

    #detail-images {
        align-items: center;
        flex-direction: column;
        gap: 20px;
        height: auto;
        left: auto;
        margin: 0 auto;
        position: relative;
        top: auto;
        width: 100%;

        .swiper-button-prev,
        .swiper-button-next {
            top: 50%;
            transform: translateY(-50%);
        }

        .swiper-button-prev {
            left: 10px;
        }

        .swiper-button-next {
            right: 10px;
        }
    }

    .product-images-mini {
        order: initial;
        height: auto;
        width: 100%;

        .swiper-wrapper {
            transform: none !important;
            width: auto;
            justify-content: center;
        }

        .size-custom {
            width: 10px;
            height: 10px;
            background-color: @border-color;
            border-radius: 50%;
            margin: 0 5px;
            padding: 0 !important;

            &.active {
                background-color: @sec-color;
            }
        }

        img {
            display: none !important;
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    //// 1279 BASE
    .headline {
        font-size: 20px !important;
        margin-bottom: 12px;
    }

    .product {
        gap: 5px;

        .product-bottom-wrapper {
            padding: 5px;
        }
    }

    .product-single-promoted .product-name,
    .comparison-product-name,
    .product a.product-name,
    .product p {
        font-size: 12px !important;
        --fontSize: 12px !important;
    }

    //// 1279 HEADER
    .top-bar {
        padding: 3px 10px 4px;
        font-size: inherit;

        a, li {

            &:before {
                font-size: 18px;
            }
        }
    }

    .shop-header {
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        background-color: white;
        .shadow();
        transition: transform .2s ease-in-out;
    }

    #tree-top,
    .mainmenu,
    .sidebar-banners-wrapper {
        display: none;
    }

    .hamburger-icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 70%;
        height: 25px;
        display: flex;
        flex-direction: column;
        gap: 4px;
        justify-content: center;

        span {
            display: block;
            height: 2px;
            border-radius: 1px;
            background-color: @header-color;

            &:nth-child(2) {
                width: 80%;
            }

        }
    }

    .shop-header-container {
        padding-block: 7px;
    }

    .logo {
        height: 60px;
    }

    // iOS 12 fixes
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        .header-tools-wrapper {

            > * {
                margin-left: 10px;
            }
        }

        .hamburger-icon {
            .grid();
            grid-template-columns: 1fr;
            grid-template-rows: repeat(3, 6px);
            gap: 0;
            justify-items: center;
            align-items: center;
        }

        .tree-canvas {
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }

    .compare-box, #cart-box, #wishlist-box, .log-button, .search-engine-trigger, .tree-trigger {
        padding-top: 29px;

        em {
            font-size: 9px;
        }
    }

    [data-type="mainpage"] {

        main.inner {
            margin-top: 16px;
        }
    }

    [data-type="listing"] {
        main.inner {
            margin-top: 0;
        }
    }

    .greeting-section + .second-section {
        margin-top: -16px;
    }

    // 1279 MAINPAGE
    .greeting-section {
        display: flex;
        gap: 16px;

        .main-banner {
            width: calc(66% - 8px);
        }

        .mainpage-greeting {
            flex: 1;
        }
    }

    .mainpage-greeting {
        gap: 8px;
        padding: 24px;

        .greeting-headline {
            font-size: 15px;
        }

        .greeting-body {
            font-size: 12px;
        }
    }

    .second-section {
        display: flex;
        gap: 16px;
    }

    .second-banner {
        width: calc(49% - 8px);
    }

    .mainpage-products-slider .promo-header {
        height: 30px;
        gap: 20px;

        a {
            white-space: nowrap;
        }

        .swiper-buttons-wrapper .products-slider-button {
            height: 30px;
            width: 30px;
        }
    }

    @media (min-width: 960px) {
        .mainpage-products-slider {
            flex: 1;
            padding: 10px;
            gap: 12px;
        }

        .product-single-promoted {
            height: 100%;
            grid-template-columns: 180px minmax(0, 1fr);
        }

        .product-single-promoted-info {

            p {
                margin-top: 6px;
                font-size: 12px;
                --fontSize: 12px;
                -webkit-line-clamp: 2;
                max-height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            }

            .product-price {
                margin-top: 6px;
                gap: 16px;
            }
        }
    }

    @media (max-width: 960px) {
        .greeting-section {
            flex-direction: column;

            .main-banner {
                width: 100%;

                .banner-text-wrapper {
                    margin: 0;
                    bottom: 5%;
                    left: 5%;
                    padding: 14px 40px 14px 32px;
                }
            }

            .mainpage-greeting {
                text-align: center;
                padding: 28px 10px;
                aspect-ratio: initial;
                height: initial;
                max-height: initial;

                .greeting-headline {
                    font-size: 20px;
                }
                
                .greeting-body {
                    font-size: 13px;
                    padding: 0;
                }
            }
        }

        .mainpage-products-slider {
            aspect-ratio: initial;
            height: initial;
            padding: 10px;
            gap: 10px;
        }

        .product-single-promoted {
            gap: 16px;
        }

        .product-single-promoted-info {

            .product-name {
                max-height: initial;
                display: block !important;
            }

            p {
                margin-top: 6px;
                font-size: 12px;
                --fontSize: 12px;
                -webkit-line-clamp: 2;
                max-height: ~"calc(2 * (var(--fontSize) * var(--lineHeight)))";
            }

            .product-price {
                gap: 16px 24px;
                flex-wrap: wrap;
            }
        }

        .second-section {
            flex-direction: column;
        }

        .second-banner {
            width: 100%;
        }
    }

    .second-banner .banner-text-wrapper {
        bottom: 5%;
        padding: 14px 40px;
    }

    .banner-text-wrapper {

        b {
            font-size: 14px;
        }

        span {
            font-size: 12PX;
        }
    }

    .mainpage-promo {
        gap: 12px;
    }

    .grid-banner .banner-text-wrapper {
        left: 0;
        right: 0;
        width: fit-content;
        margin: 0 auto !important;
        max-width: 90%;
        top: initial;
        bottom: 0;

        b {
            font-size: 12px;
            font-weight: normal;
        }

        i, p {
            display: none !important;
        }
    }

    .grid-banners-wrapper {
        gap: 16px;
    }

    .blog-banners-section {
        gap: 12px;
    }

    .blog-banners-wrapper {
        gap: 20px 16px;
    }

    .blog-banner {
        gap: 12px;

        p {
            font-size: 12px;
            --fontSize: 12px;

            &:nth-child(3) {
                margin-top: -6px;
            }
        }

        i {
            height: 27px;
            padding: 8px 12px;
        }
    }

    // 1279 FOOTER
    .producers-banner {
        padding: var(--rowGap);
    }

    .footer-content > * {
        flex-basis: initial !important;
        max-width: initial !important;

        > strong {
            margin-bottom: 18px;
        }
    }

    .location-hours {

        > div {
            padding-left: 32px;

            svg,
            &:before {
                width: 20px;
                font-size: 20px;
            }
        }
    }

    .footer-contact {

        > strong {
            margin-bottom: 16px;
        }

        .contact-list a {

            &:before {
                font-size: 18px;
            }
        }
    }

    .footer-map {
        margin-top: 0;
        
        &:before {
            display: none;
        }
    }

    .footer-bottom-bar {
        padding-block: 20px 70px;
        flex-direction: column;
        gap: 16px;
    }

    // LISTING
    #breadcrumb {
        padding-block: 10px;
    }

    .listing-wrapper {
        gap: var(--rowGap);
    }

    .listing-button.filter-trigger {
        margin-left: auto;
        font-size: 0;
        color: @main-color;
        border: 1px solid @main-color;
        
        &:before {
            content: 'Filtry i sortowanie';
            font-size: 13px;
        }

        &:after {
            font-size: 20px;
            color: @main-color;
        }
    }

    .listing-button.page-size {
        min-width: initial !important;
    }

    .inner-wrapper
    [data-dropdown-type="pageSize"] {
        margin-left: initial;
    }

    .pagination a:after {
        font-size: 20px;
    }

    // 1279 DETAIL
    .detail-stars-wrapper i {
        position: relative;
        top: -1px;
    }

    .detail-headline {
        text-align: center;
    }

    #detail-left {
        margin-left: 0;

        > * {
            padding-bottom: 20px;
        }
    }

    #detail-right {
        position: relative;
        top: initial;
        box-shadow: none;
        padding: 20px 10px;
    }

    #main-info {
        margin-top: 0;
    }

    .detail-promo-badge {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .detail-producer-logo {
        max-height: 50px;
    }
    
    .detail-pricing {
        justify-content: flex-start;

        &:before {
            display: none;
        }

        &:after {
            content: 'Cena brutto:';
            width: 100%;
            display: flex;
            justify-content: inherit;
            font-size: 12px;
        }

        del {
            order: 2;
        }

    }

    [data-role="product-nettoprice"] {
        font-size: 16px;
        justify-content: flex-start;
    }

    .detail-omnibus {
        text-align: start;
        margin-top: -14px;
    }

    #detail-tabs {

        nav {
            gap: 16px;
            margin-bottom: 16px;

            button {
                font-size: 16px;
                min-height: 42px;
                padding-bottom: 8px;
            }
        }
    }

    // 1279 COMMENTS
    .detail-comments-header {
        gap: 4px 10px;

        > b {
            font-size: 20px;
            margin-right: 0;
        }
    }

    .comments-list {
        gap: 24px;
        grid-template-columns: minmax(0, 1fr);
    }

    .comment-info {
        padding-inline: 0;
        justify-content: flex-start;
        gap: 20px;

        .purchase-confirmed {
            white-space: nowrap;
            margin-left: auto;

            svg {
                height: 20px;
                width: 20px;
                margin-right: 7px;
            }

            &:after {
                margin-inline: 16px;
                height: 24px;
            }
        }
    }

    .comment {
        gap: 10px;
    }

    .comment-response {
        margin-left: 16px;
    }

    .comments-stars-wrapper {
        white-space: nowrap;

        b {
            font-size: 16px;
        }

        i {
            font-size: 14px;
        }
    }

    .comparison-drawer {
        gap: 8px;
        padding: 10px;

        > b {
            font-size: 12px;
        }

        .confirm-button {
            gap: 6px;
            font-size: 11px;
            padding: 6px 12px;

            &:before {
                font-size: 16px;
            }
        }
    }

    // COMPARISON PAGE 1279
    .infobox.comparison-empty {

        &:not(:only-child) {

            h3 {
                font-size: 20px;
            }
        }
    }
}
