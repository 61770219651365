// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {

    #detail-traits td {
        padding: 8px 15px;
    }

    @media (max-width: 675px) {
        .icon-banner-wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    @media (max-width: 750px) {
        .product {

            .brutto-price {
                justify-content: center;
            }

                .brutto-price b,
                .netto-price {
                    text-align: center;

                    &:before {
                        text-align: center;
                    }
                }

            .product-add {
                width: 100%;
                grid-column: 1 / 3;
                justify-content: center;
                margin-top: 4px;
            }
        }
    }

    .header-tools-wrapper {
        gap: 24px;
    }

    .top-bar {
        gap: 16px;

        .topbar {
            gap: inherit;
        }
    }

    @media(min-width: 455px) {
        .products-list.list-view {
            gap: 16px;
        }

        .list-view .product {
            padding-inline: 16px 8px;
            grid-template-columns: 180px minmax(0, 1fr) auto;
            gap: 16px;

            .middle-info {
                padding-block: 16px;
            }

            .product-bottom-wrapper {
                padding-block: 16px;
                padding-inline: 16px 8px;
            }
        }

        @media(max-width: 800px) {
            .list-view .product {
                grid-template-columns: 120px minmax(0, 1fr) auto;
            }
        }
    }

    @media(max-width: 700px) {
        .shop-header-container {
            flex-direction: column;
            justify-content: center;
            gap: 8px;
            padding-block: 4px 8px;

            .logo {
                height: 56px;
            }

            .header-tools-wrapper {
                margin-right: 0;
                justify-content: space-evenly;
                width: 100%;
                gap: 16px;
            }
        }
    }

    @media(max-width: 900px) {
        .top-bar .hours {
            display: none;
        }
    }

    .grid-banners-wrapper {
        gap: 8px;
        grid-template-columns: repeat(3, minmax(0, 1fr));

        b {
            font-size: 11px !important;
        }
    }

    @media (max-width: 700px) {
        .grid-banners-wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }

    }

    @media (max-width: 800px) {

        .blog-banners-wrapper {
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    .top-bar {
        gap: 12px;
        font-size: 11px;

        .topbar a {
            font-size: 11px;
        }
    }

    .header-contact {
        letter-spacing: inherit;

        li {
            gap: 6px;

            &:before {
                font-size: 16px;
            }
        }
    }

    @media(max-width: 800px) {
        .product-single-promoted {
            grid-template-columns: 180px minmax(0, 1fr);
        }

        .greeting-section .mainpage-greeting {
            padding: 20px 10px;
        }
    }

    .blog-banners-wrapper {
        gap: 20px 8px;
    }

    // 1100 TRAITS
    #detail-traits {
        gap: 0;

        td {
            padding: 8px 15px;
        }

        .trait {
            flex-direction: column;
            gap: 6px;
            padding: 8px 0;

            b {
                width: 100%;
                justify-content: flex-start;
            }

            .trait-desc {
                width: 100%;
            }
        }
    }

    // 1100 COMMENTS
    .comments-list {
        grid-template-columns: minmax(0, 1fr);
        gap: 24px;
    }

    .comment-info {
        .grid();
        grid-template-columns: minmax(0, 7fr) minmax(0, 3fr);
        gap: 8px;

        .purchase-confirmed {
            margin-left: 0;

            &:after {
                display: none;
            }
        }

        .stars-rating {
            justify-self: end;
        }

        .added-date {
            justify-self: end;
            text-align: end;
        }
    }

    // COMPARISON PAGE 750
    @media (max-width: 750px) {
        .infobox.comparison-empty {

            &:not(:only-child) {

                .confirm-button {
                    display: none;
                }
            }
        }

        .comparison-header {
            gap: var(--prodGap);
            flex-wrap: wrap;

            .comparison-empty {
                width: 100%;
                flex-basis: auto;
            }
        }

        .comparison-product {
            gap: 12px;

            .comparison-info-bottom {
                padding: 8px;
            }
        }

        .comparison-table {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 24px;
            padding-bottom: 0;

            .row {
                display: flex;
                flex-direction: column;
                gap: 10px;
                border: none !important;

                > div {
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: flex-end;

                    &:empty {
                        display: none !important;
                    }

                    &:first-child {
                        justify-content: flex-start;
                        margin: 0;
                        width: 100% !important;
                        flex-basis: auto;
                        text-align: left;
                        display: block;
                        line-height: normal;
                        background-color: @light-bg;
                        border-radius: 4px;
                        color: @font-color;
                        padding: 6px 10px;
                        text-transform: uppercase;
                        font-size: 14px;
                        letter-spacing: 0.08em;

                        &:after {
                            content: ':';
                        }
                    }

                    &:not(:first-child) {
                        display: flex;
                        flex-wrap: wrap;
                        font-weight: bold;
                        color: @header-color;

                        &:before {
                            content: attr(data-model-name)':';
                            font-weight: normal;
                            margin-right: 8px;
                            color: @font-color;
                        }
                    }
                }
            }
        }
    }
}